<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm">
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1"></i>
			<span class="download">上传服务条款</span>
		</div>
		<div class="uploadFile">
			<div class="uploadFile-item">
				<p class="title">1.上传服务须知</p>
				<div class="uploadFile-btn" v-if="servicefileList.length == 0">
					<img src="@/assets/imgs/uploadFile.png" alt="" />
					<el-upload
						action=""
						multiple
						:before-upload="beforeUpload"
						:http-request="uploadURL"
						:on-error="importError"
						:file-list="servicefileList"
						:show-file-list="false"
					>
						<el-button>选择文件</el-button>
					</el-upload>
					<p class="tips">文件支持格式：PDF</p>
				</div>
				<div class="uploadFile-fileList" v-else>
					<p v-for="item in servicefileList" :key="item.uid" class="clearfix">
						<i class="el-icon-document"></i> {{ item.title }}
						<i class="el-icon-close float-r" @click="delServicefileList(item, 'servicefileList')"></i>
					</p>
				</div>
			</div>
			<div class="uploadFile-item">
				<p class="title">2.上传服务内容及条款</p>
				<div class="uploadFile-btn" v-if="fileList.length == 0">
					<img src="@/assets/imgs/uploadFile.png" alt="" />
					<el-upload
						action=""
						multiple
						:before-upload="beforeUpload1"
						:http-request="uploadURL1"
						:on-error="importError1"
						:file-list="fileList"
						:show-file-list="false"
					>
						<el-button>选择文件</el-button>
					</el-upload>
					<p class="tips">文件支持格式：PDF</p>
				</div>
				<div class="uploadFile-fileList" v-else>
					<p v-for="item in fileList" :key="item.uid" class="clearfix">
						<i class="el-icon-document"></i> {{ item.title }} <i class="el-icon-close float-r" @click="delServicefileList(item, 'fileList')"></i>
					</p>
				</div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="onSave" :loading="saveLoading">保 存</el-button>
			<el-button @click="onResetForm">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { client, headers, getFileNameUUID } from '@/utils/alioss.js';
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			servicefileList: [],
			fileList: [],
			saveLoading: false,
			link: '',
			attachmentInfo1: [],
			attachmentInfo2: [],
			updateInfo: {},
		};
	},
	watch: {},
	created() {},
	methods: {
		init(params) {
			this.createdVisible = true;
			this.getAttachment1();
			this.getAttachment2();
			this.link = params.link;
			if (params.row) {
				this.updateInfo = params.row;
				this.servicefileList = params.row.serviceNotice.map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				});
				this.fileList = params.row.serviceContentAndTerms.map((item) => {
					return {
						url: item.link,
						uid: item.id,
						title: item.title,
					};
				});
			}
		},
		// 获取附件组1
		getAttachment1() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HEALTH_SERVICE_TERM' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo1 = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取附件组2
		getAttachment2() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HEALTH_SERVICE_INSTRUCTION' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo2 = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		delServicefileList(item, type) {
			this[type] = this[type].filter((v) => v.uid !== item.uid);
		},
		// 上传服务内容及条款
		uploadURL(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_INSTRUCTION' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.servicefileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 上传服务须知
		uploadURL1(file) {
			let fileName = 'bernieri/zhangl/HEALTH_SERVICE_TERM' + `/${getFileNameUUID()}` + '.pdf';
			client()
				.multipartUpload(fileName, file.file, {
					headers,
				})
				.then((res) => {
					const path = 'https://qdincrease-cd.oss-cn-chengdu.aliyuncs.com/' + res.name;
					if (res.res.status == 200) {
						this.$message({
							type: 'success',
							message: '导入成功',
						});
						this.$loading().close();
						this.fileList.push({
							title: file.file.name,
							url: path,
							uid: file.file.uid,
							name: file.file.name,
						});
					}
				});
		},
		beforeUpload1(file) {
			let lastLen = file.name.lastIndexOf('.');
			let len = file.name.len;
			let type = file.name.substring(lastLen, len);
			if (type !== '.pdf') {
				this.$message({
					type: 'error',
					message: '请导入pdf文件',
				});
				return false;
			}
			this.$loading({
				lock: true,
				text: '导入中',
				background: 'rgba(0, 0, 0, 0.7)',
				spinner: 'el-icon-loading',
			});
		},
		importError1(err, file, fileList) {
			let msg = err.toString().split('"msg":')[1];
			let showMsg = msg.length > 0 ? msg.split('"') : [];
			this.$message({
				type: 'error',
				message: showMsg[1] || '导入失败',
			});
			this.$loading().close();
		},
		// 重置
		onResetForm() {
			this.createdVisible = false;
			this.servicefileList = [];
			this.fileList = [];
		},
		// 保存
		onSave() {
			if (this.servicefileList.length == 0) {
				this.$message.error('请上传服务须知');
				return;
			}
			if (this.fileList.length == 0) {
				this.$message.error('请上传服务内容及条款');
				return;
			}
			this.saveLoading = true;
			let fileList1 = this.servicefileList.map((item) => {
				return {
					link: item.url,
					subjectTypeCode: this.attachmentInfo2.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
					title: item.title,
					attachmentGroupId: this.attachmentInfo2.find((item) => item.code == 'DOCUMENT').id,
					mimeType: item.title.split('.')[1],
				};
			});
			let fileList2 = this.fileList.map((item) => {
				return {
					link: item.url,
					subjectTypeCode: this.attachmentInfo1.find((item) => item.code == 'DOCUMENT').subjectTypeCode,
					title: item.title,
					attachmentGroupId: this.attachmentInfo1.find((item) => item.code == 'DOCUMENT').id,
					mimeType: item.title.split('.')[1],
				};
			});
			let params = {
				...this.updateInfo,
				attachments: [...fileList1, ...fileList2],
			};
			let promiseUrl = this.updateInfo.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
			promiseUrl
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success(this.updateInfo.id ? '修改成功' : '添加成功');
						this.$emit('getList');
						this.onResetForm();
						this.saveLoading = false;
					}
				})
				.catch((e) => {
					this.saveLoading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
.uploadFile {
	.title {
		color: #333;
		line-height: 14px;
		height: 14px;
		margin-bottom: 20px;
	}
	&-item {
		background-color: #f8f9fa;
		padding: 24px;
		margin-bottom: 12px;
	}
	&-btn {
		background-color: #fff;
		padding: 32px 0;
		text-align: center;
		img {
			margin-bottom: 12px;
			width: 48px;
			height: 48px;
		}
		.tips {
			margin-top: 12px;
			color: #999;
			line-height: 14px;
			height: 14px;
		}
	}
	&-fileList {
		p {
			margin-top: 12px;
			color: #1db9b1;
			height: 14px;
			line-height: 14px;
			.el-icon-document {
				margin-right: 7px;
			}
			.el-icon-close {
				display: none;
			}
			&:hover {
				.el-icon-close {
					display: block;
					cursor: pointer;
				}
			}
		}
	}
}
</style>